.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.calendar.right::before {
  display: none !important;
}

.calendar.left::before {
  display: none !important;
}

.ranges .range_inputs {
  text-align: center !important;
}

.ranges .range_inputs .applyBtn {
  margin-right: 5px !important;
}

.App-link {
  color: #61dafb;
}

.text-label-readonly {
  font-size: 24px;
  color: #64B5F6;
  font-style: normal;

  font-family: Gill Sans MT;
}

.css-h5owck-control {
  border: none !important;
  height: 35px !important;
  min-height: 0px !important;
  flex-wrap: unset !important;
}

.rupee-icon {
  font-size: .875em;
  /* padding-right: 5px; */
  position: relative;
  right: 5px;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fa.fa-inr.fa-sm {
  padding-right: 5px;
}


.disabled-cursor {
  cursor: not-allowed;
  color: #9e9e9e;
  appearance: none;
  background-image: none;
}

.light-name {
  color: #9e9e9e;
}

.text-green {
  color: #26C281 !important;
}

.bg-badge {
  padding: 12px 0;
  line-height: 0;
}

.newtags {
  display: inline-block;
  margin-top: 2px;
  margin-bottom: 2px;
}

.tagheight {
  line-height: 1 !important;
}

/* @media (min-width: 768px){
    .img-box-sm{
        min-height: 150px;
        height: 347px;
    }
} */


.css-1kngj9z-multiValue {
  background-color: #fff !important;
}

.css-ito2ml:hover {
  background-color: unset !important;
  color: unset !important;
}

.css-g1d714-ValueContainer {
  padding: 0px !important;
}

/* @media (max-width: 280px), (max-width: 320px), (max-width: 375px), (max-width: 414px) {
    .datechart{
      width: 100% !important;
    }
  }
@media (max-width: 667px){
    .datechart{
       width: 410px;
    }
} */


@media (max-width: 640px),
(max-width: 768px) {
  .page-logo {
    display: flex !important;
    align-items: center;
    /* max-width: 155px; */
  }

}

.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: -10px solid #3498db;
  width: 25px;
  height: 20px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

body {
  background-color: #e3e1e1 !important;
}

.page-content {
  margin-top: 0;
  padding: 0;
  background-color: #e2e0e0a1;
}

.form-control.invoice-f:disabled,
.form-control.invoice-f[readonly] {
  background-color: #e9ecef;
  opacity: 1;
  padding-left: 10px;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #ffffff;
  opacity: 1;
  border: 0px;
  padding-left: 0px;
}

.f-12 {
  font-size: 12px;
}

.f-16 {
  font-size: 16px;
}

.btn-app-btn {
  background: linear-gradient(180deg, #FF4081 0%, #F50057 100%);
  border-radius: 4px;
}


.form-control-sm {
  height: calc(1.5em + .5rem + 2px);
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
  border-radius: .2rem;
  border: 1px solid #fff;
}

/*************** otstanding modal view css ******************/
.filter-wrap {
  background: #ffffff;
  padding: 2px 8px;
  font-size: 13px;
  cursor: pointer;
}

.filter-wrap.active-filter {
  background: #add8e6;
  color: #000;
  cursor: pointer;
}

.filter-wrap:hover {
  background: #add8e6;
  color: #000;
  cursor: pointer;
}

.pay-received {
  color: #4caf50 !important;
  margin-bottom: 2px;
}

.unadjustable-row {
  color: #fff !important;
  opacity: 1;
  background-image: linear-gradient(180deg, #a2abde 0%, #1e2a8cc9 100%) !important;
}

.unadjustable-row .pay-received {
  color: #fff !important;
}

.inputgroup-span {
  background: #fff;
  border: 1px solid #fff;
}

.unadjustable-row:hover {
  color: #fff !important;
  opacity: 1;
  background-image: linear-gradient(180deg, #a2abde 0%, #1e2a8cc9 100%) !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

.form-control-sm:focus {
  outline: 0px;
  border: 0px;
}

p.address-p {
  color: #626262;
  font-size: 14px;
}

a.edit-action {
  position: absolute;
  left: 81px;
  top: -2px;
}

h3.address-t {
  font-size: 16px;
}

.border-grey {
  border: 1px solid #d2d1d1 !important;
}

.border-secondary {
  border-color: #e1e2e2 !important;
}

thead {
  background: rgba(100, 181, 246, 0.22);
  color: #000;
}

.add-row-btn {
  background: linear-gradient(180deg, #FF4081 0%, #F50057 100%);
  border-radius: 4px;
  padding: 8px 13px;
  color: #fff;
}

.add-row-btn:hover {
  background: linear-gradient(180deg, #FF4081 0%, #F50057 100%);
  border-radius: 4px;
  padding: 8px 13px;
  color: #fff;
}

.add-row-btn-pro {
  background: linear-gradient(180deg, #FF4081 0%, #F50057 100%);
  border-radius: 2px;
  padding: 1px 5px;
  color: #fff;
}

.add-row-btn-pro:hover {
  background: linear-gradient(180deg, #FF4081 0%, #F50057 100%);
  border-radius: 2px;
  padding: 1px 5px;
  color: #fff;
}

.update-row-btn {
  background-color: #f9484a;
  background-image: linear-gradient(315deg, #f9484a 0%, #fbd72b 74%);
  border-radius: 4px;
  padding: 8px 13px;
  color: #fff;
}

.update-row-btn:hover {
  /* background-color: #f9484a;
    background-image: linear-gradient(315deg, #f9484a 0%, #fbd72b 74%); */
  border-radius: 4px;
  padding: 10px 13px;
  color: #fff;
  vertical-align: sub;
}

.c-select {
  border-radius: 4px;
  border-color: #d4c2c2;
  width: 55px;
  height: calc(1.5em + .75rem + 2px);
}

.img-box {
  width: 100%;
  min-height: 218px;
  border: 1px solid #fff;
  background: #fff;
}

label.readonly {
  opacity: 1;
  background: rgba(100, 181, 246, 0.22) !important;
  mix-blend-mode: normal;
  border-radius: 4px;
  border: 0;
}

span.readonly {
  opacity: 1;
  background: rgba(100, 181, 246, 0.22) !important;
  mix-blend-mode: normal;
  border-radius: 4px;
  /* padding: 6px 3px;
    min-width:50px;
    height: 36px;
    display: inline-block;
    vertical-align: middle; */
}

.image-preview {
  height: 200px;
}

.td.none {
  display: none;
}

@font-face {
  font-family: 'gill-sans-mt';
  src: local('gill-sans-mt'), url(../fonts/gill-sans-mt.ttf) format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'palatino-linotype';
  src: local('palatino-linotype'), url(../fonts/palatino-linotype.ttf) format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'palatino-linotype-bold';
  src: local('palatino-linotype-bold'), url(../fonts/palatino-linotype-bold.ttf) format('truetype');
  font-weight: 600;
  font-style: normal;
}

.text-app-icon-grey {
  color: #d2cdcd;
}

.text-app-grey {
  color: #9E9E9E;
}

div#reportrange span {
  color: #9E9E9E;
}

.f-14 {
  font-size: 14px;
}

#card2 {
  background: linear-gradient(180deg, #827717 0%, #AFB42B 100%);
  box-shadow: 1px 1px 25px rgba(152, 148, 148, 0.2);
}

.qty-field {
  width: 40px;
  border: 1px solid #ced4da;
}

.cursor-pointer {
  cursor: pointer;
}


/************************ float-button-css *******************/

.add-float {
  background-color: #e40a5da3;
  display: inline-block;
  position: relative;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
  cursor: pointer;
  outline: none;
  padding: 0;
  position: relative;
  -webkit-user-drag: none;
  font-weight: bold;
  color: #f1f1f1;
}

.float-pos {
  position: fixed;
  right: 24px;
  bottom: 45px;
}

.btn-floating.btn-large {
  width: 56px;
  height: 56px;
  padding: 0;
  transition: width 2s;
}

.text-btn {
  display: none;
}

.btn-floating.btn-large:hover .text-btn {
  display: inline-block;
}

.add-float {
  width: 53px;
  height: 53px;
  overflow: hidden;
  -webkit-transition: width 1s;
  transition: width 1s;
  white-space: nowrap;
  /*this was suggested by jaunt, thanks */
  z-index: 9999;
}

.add-float:hover {
  width: 150px;
  -webkit-transition: width 0.5s;
  transition: width 0.5s;
  border-radius: 30px;
  z-index: 9999;
}

.create-invoice:hover::after {
  content: 'Create Invoice';
  padding-left: 4px;
}

.receipts-voucher:hover::after {
  content: 'Receipts Voucher';
  padding-left: 4px;
}

button#btn:focus {
  border: 0px;
  outline: 0;
}

.payment-voucher:hover::after {
  content: 'Payment Voucher';
  padding-left: 4px;
}

button#btn:focus {
  border: 0px;
  outline: 0;
}

.purchase-order:hover::after {
  content: 'Purchase Order';
  padding-left: 4px;
}

button#btn:focus {
  border: 0px;
  outline: 0;
}

.preview:hover::after {
  content: 'Preview';
  padding-left: 4px;
}

.cancel:hover::after {
  content: 'Cancel';
  padding-left: 4px;
}

button#btn:focus {
  border: 0px;
  outline: 0;
}

.heads:hover::after {
  content: 'Heads';
  padding-left: 4px;
}

button#btn:focus {
  border: 0px;
  outline: 0;
}

.create-brand:hover::after {
  content: 'Create Brand';
  padding-left: 4px;
}

button#btn:focus {
  border: 0px;
  outline: 0;
}

.create-supplier:hover::after {
  content: 'Create Supplier';
  padding-left: 4px;
}

button#btn:focus {
  border: 0px;
  outline: 0;
}

.create-category:hover::after {
  content: 'Create Category';
  padding-left: 4px;
}

button#btn:focus {
  border: 0px;
  outline: 0;
}

.create-product:hover::after {
  content: 'Create Product';
  padding-left: 4px;
}

.create-branch:hover::after {
  content: 'Create Branch';
  padding-left: 4px;
}

.make-transfer:hover::after {
  content: 'Make Transfer';
  padding-left: 4px;
}

button#btn:focus {
  border: 0px;
  outline: 0;
}

.transfer-list:hover::after {
  content: 'Transfer List';
  padding-left: 4px;
}

button#btn:focus {
  border: 0px;
  outline: 0;
}

.create-vendor:hover::after {
  content: 'Create Vendor';
  padding-left: 4px;
}

button#btn:focus {
  border: 0px;
  outline: 0;
}

.create-customer:hover::after {
  content: 'Create Customer';
  padding-left: 4px;
}

button#btn:focus {
  border: 0px;
  outline: 0;
}

.expense-heads:hover::after {
  content: 'Account Head';
  padding-left: 4px;
}

button#btn:focus {
  border: 0px;
  outline: 0;
}

.create-manager:hover::after {
  content: 'Create Manager';
  padding-left: 4px;
}

.create-identity:hover::after {
  content: 'Create E-Identity';
  padding-left: 4px;
}

.create-employee:hover::after {
  content: 'Create Employee';
  padding-left: 4px;
}

button#btn:focus {
  border: 0px;
  outline: 0;
}


/************************ float-button-css *******************/

.radius-search {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

.addon-radius {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

.outer-box {
  -moz-box-shadow: 0px 2px 6px 3px #dadadc inset;
  -webkit-box-shadow: 0px 2px 6px 3px #dadadc inset;
  box-shadow: 0px 2px 6px 3px #dadadc inset;
  border-radius: 30px !Important;
  background: #fff;
}

.cursor-pointer {
  cursor: pointer;
}

.text-app-dark-grey {
  color: #9E9E9E;
}

.btn-grey {
  background: #d2cdcd;
}

.btn-light-grey {
  background: #d2cdcd;
}

.search-box {
  border: 0px;
  border-radius: 30px;
  width: 300px;
  padding: 4px 0px;
  font-size: 12px;
  height: 35px;
  margin-right: 10px;
  display: inline-block;
}

.search-box:focus {
  border: 0px;
  outline: 0;
}

.search-box::placeholder {
  opacity: 1 !important;
}

.serachbar:hover .serach-box {
  display: inline-block;
}

.serachbar:hover .text-app-dark-grey {
  color: #1A237E !important;
}

.branch-box {
  color: #1A237E !important;
  border: 0px;
  border-radius: 30px;
  width: 300px;
  padding: 2px 16px;
  font-size: 12px;
  margin-right: 10px;
  display: inline-block;
}

.bs-select {
  font-size: 13px;
  height: 34px;
  padding: 0px 10px;
}

.tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  background: linear-gradient(180deg, #827717 0%, #AFB42B 100%);
  border-radius: .25rem;
}

.tooltip.bs-tooltip-auto[x-placement^=top] .arrow::before,
.tooltip.bs-tooltip-top .arrow::before {
  margin-left: -3px;
  content: "";
  border-width: 5px 5px 0;
  border-top-color: #AFB42B;
}

.placeholder-box-table {
  width: 100%;
  min-height: 553px;
  background-color: #bcbcbc;
  height: 12px;
  margin: 5px 0;
  min-width: 100px;
  opacity: .1;
  animation: fading 1.5s infinite;
}

.placeholder-box-summary-cards {
  width: 100%;
  min-height: 70px;
  background-color: #bcbcbc;
  height: 12px;
  margin: 5px 0;
  min-width: 100px;
  opacity: .1;
  animation: fading 1.5s infinite;
}

.placeholder-box-new-title {
  /* width: 100%;
    min-height: 30px; */
  background-color: #bcbcbc;
  /* height: 12px;
    margin: 5px 0;
    min-width: 100px; */
  opacity: .1;
  animation: fading 1.5s infinite;
}

.placeholder-box-title {
  width: 100%;
  min-height: 30px;
  background-color: #bcbcbc;
  height: 12px;
  margin: 5px 0;
  min-width: 100px;
  opacity: .1;
  animation: fading 1.5s infinite;
}

@keyframes fading {
  0% {
    opacity: .1;
  }

  25% {
    opacity: .2;
  }

  50% {
    opacity: .3;
  }

  100% {
    opacity: .1;
  }
}



svg {
  width: 40px;
  height: 40px;
}


.branch-box:focus {
  border: 0px;
  outline: 0;
}

.date-label {
  vertical-align: top;
}

.branch-icon {
  height: 30px;
  display: inline-flex;
  vertical-align: top;
  align-items: center;
}

.text-app-light-blue {
  color: #90A4AE;
}

.text-app-light-blue:hover {
  color: #455A64;
}


/* .branch:hover .text-app-dark-grey {
    color:#1A237E!important;
    }
.branch:hover .branch-box {
display:inline-block;
margin-left:3px; */


/* } */

.date-box {
  border: 0px;
  border-radius: 30px;
  width: 300px;
  padding: 0px 16px;
  font-size: 12px;
  margin-left: 4px;
  display: ninline-blockone;
  color: #1A237E !important;
}


/* .date-box:focus {
    border:0px;
    outline:0;
}
.date:hover .text-app-dark-grey {
    color:#1A237E!important;
    }
.date:hover .date-box {
display:inline-block; */


/* } */

.branch-tags-c {
  border: 1px solid #add8e6 !important;
  font-size: 12px;
  /* background: linear-gradient(180deg, #64B5F6 0%, #2196F3 100%); */
  /* box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25); */
  border-radius: 20px !important;
  background: #add8e6 !important;
  color: #1a237e !important;
  padding: 2px 10px;
  color: #000;
}

.daterangepicker .ranges ul>li.active {
  border-radius: 0px;
  margin: 0px -10px;
  padding: 10px 23px;
}

.daterangepicker .ranges li.active,
.daterangepicker .ranges li:hover {
  background: #c4ebfd !important;
  border: 1px solid #c4ebfd !important;
  margin: 0px -10px;
  padding: 10px 23px;
  border-radius: 0px;
  color: #000;
}

.daterangepicker .ranges li {
  font-size: 13px;
  color: #000;
  cursor: pointer;
  margin: 0px -10px;
  padding: 10px 23px;
  border-radius: 0px;
}

.daterangepicker.dropdown-menu,
.datetimepicker.dropdown-menu {
  padding: 0px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
}

.daterangepicker .ranges {
  width: 173px !important;
}

.daterangepicker .ranges li {
  background: #ffffff;
  border: 1px solid #ffffff;
}

.list-group-item.d-flex.justify-content-between.align-items-center.position-relative {
  height: 72px;
}

.date-box.position-absolute {
  bottom: 4px;
  left: 8px;
}

.badge {
  text-transform: capitalize;
  font-weight: 300;
  min-width: 64px;
  line-height: 10px;
  font-size: 12px !important;
}

.blueGradient {
  background: linear-gradient(180deg, #64B5F6 0%, #2196F3 100%);
  padding: 12px 0px;
  line-height: 0px;
}

.trans-price {
  font-size: 18px;
}

.disable-app-btn {
  background: #3e3e3e7a;
  color: #000;
  opacity: 0.8;
  box-shadow: none !important;
  cursor: not-allowed;

}

.disable-app-btn:hover {
  background: #3e3e3e7a !important;
  color: #000;
  opacity: 0.8;
  box-shadow: none !important;
  cursor: not-allowed;
}

button.btn.disable-app-btn.btn-app.btn-sm {
  background: #3e3e3e7a !important;
  box-shadow: none !important;
}

.active-tick {
  position: absolute;
  top: 6px;
}

.table-none {
  display: none !important;
}

.Debit .badge {
  background: linear-gradient(180deg, #4CE99C 0%, #00C853 100%);
}

.Credit .badge {
  background: linear-gradient(180deg, #64B5F6 0%, #2196F3 100%);
}

.pay-mode {
  left: 0px;
  bottom: -15px;
  color: #64B5F6;
  font-size: 12px;
}

.Credit .price-box {
  margin-right: 84px;
  color: #2d9bf3;
}

.Debit .price-box {
  color: #00C853;
}

.payment-sel li {
  /* float: left; */
  display: inline-flex;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 1rem;
}

.payment-sel {
  margin-bottom: 0px !important;
}

.payment-box-active .pay-icon {
  /* display: none; */
  visibility: hidden;
}

img.rs-icon-lg {
  width: 14px;
}

.active-tick {
  visibility: hidden;
}

.payment-box-active .active-tick {
  /* display: none; */
  visibility: visible;
}

img.img-responsive {
  margin-left: 350px;
}

.branch-selects {
  left: 105px;
  top: -1px;
}

.gradient-border .branch-select {
  left: 94px;
  top: 10px;
}

.cart-mini {
  min-height: 75px;
  padding: 15px 10px;
}

.layout-view {
  position: absolute;
  right: 15px;
}

h3.h3 {
  text-align: center;
  margin: 1em;
  text-transform: capitalize;
  font-size: 1.7em;
}


/********************* shopping Demo-1 **********************/

.product-grid {
  font-family: Raleway, sans-serif;
  width: 100%;
  background: #fff;
  text-align: center;
  padding: 0 0 72px;
  border: 1px solid rgba(0, 0, 0, .1);
  overflow: hidden;
  position: relative;
  z-index: 1
}

.product-grid .product-image {
  position: relative;
  transition: all .3s ease 0s
}

.product-grid .product-image a {
  display: block
}

.product-grid .product-image img {
  width: 90%;
  height: auto;
  margin: 3px;
}

.product-grid .pic-1 {
  opacity: 1;
  transition: all .3s ease-out 0s
}

.product-grid:hover .pic-1 {
  opacity: 1
}

.product-grid .pic-2 {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  transition: all .3s ease-out 0s
}

.product-grid:hover .pic-2 {
  opacity: 1
}

.product-grid .social {
  width: 150px;
  padding: 0;
  margin: 0;
  list-style: none;
  opacity: 0;
  transform: translateY(-50%) translateX(-50%);
  position: absolute;
  top: 60%;
  left: 50%;
  z-index: 1;
  transition: all .3s ease 0s
}

.product-grid:hover .social {
  opacity: 1;
  top: 50%
}

.product-grid .social li {
  display: inline-block
}

.product-grid .social li a {
  color: #fff;
  background-color: #333;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
  height: 40px;
  width: 40px;
  margin: 0 2px;
  display: block;
  position: relative;
  transition: all .3s ease-in-out
}

.product-grid .social li a:hover {
  color: #fff;
  background-color: #ef5777
}

.product-grid .social li a:after,
.product-grid .social li a:before {
  content: attr(data-tip);
  color: #fff;
  background-color: #000;
  font-size: 12px;
  letter-spacing: 1px;
  line-height: 20px;
  padding: 1px 5px;
  white-space: nowrap;
  opacity: 0;
  transform: translateX(-50%);
  position: absolute;
  left: 50%;
  top: -30px
}

.product-grid .social li a:after {
  content: '';
  height: 15px;
  width: 15px;
  border-radius: 0;
  transform: translateX(-50%) rotate(45deg);
  top: -20px;
  z-index: -1
}

.product-grid .social li a:hover:after,
.product-grid .social li a:hover:before {
  opacity: 1
}

.product-grid .product-discount-label,
.product-grid .product-new-label {
  color: #fff;
  background-color: #ef5777;
  font-size: 12px;
  text-transform: uppercase;
  padding: 2px 7px;
  display: block;
  position: absolute;
  top: 10px;
  left: 0
}

.product-grid .product-discount-label {
  background-color: #333;
  left: auto;
  right: 0
}

.product-grid .rating {
  color: #FFD200;
  font-size: 12px;
  padding: 12px 0 0;
  margin: 0;
  list-style: none;
  position: relative;
  z-index: -1
}

.product-grid .rating li.disable {
  color: rgba(0, 0, 0, .2)
}

.product-grid .product-content {
  background-color: #fff;
  text-align: center;
  padding: 12px 0;
  margin: 0 auto;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -27px;
  z-index: 1;
  transition: all .3s
}

.product-grid:hover .product-content {
  bottom: 0
}

.product-grid .title {
  font-size: 13px;
  font-weight: 400;
  letter-spacing: .5px;
  text-transform: capitalize;
  margin: 0 0 10px;
  transition: all .3s ease 0s
}

.product-grid .title a {
  color: #828282
}

.product-grid .title a:hover,
.product-grid:hover .title a {
  color: #ef5777
}

.product-grid .price {
  color: #333;
  font-size: 17px;
  font-family: Montserrat, sans-serif;
  font-weight: 700;
  letter-spacing: .6px;
  margin-bottom: 8px;
  text-align: center;
  transition: all .3s
}

.product-grid .price span {
  color: #999;
  font-size: 13px;
  font-weight: 400;
  text-decoration: line-through;
  margin-left: 3px;
  display: inline-block
}

.product-grid .add-to-cart {
  color: #000;
  font-size: 13px;
  font-weight: 600
}

@media only screen and (max-width:990px) {
  .product-grid {
    margin-bottom: 30px
  }
}


/********************* Shopping Demo-6 **********************/

.product-grid6,
.product-grid6 .product-image6 {
  overflow: hidden
}


.bg-darkgray {
  background-color: #6c757d !important;
}

.product-grid6 {
  font-family: 'Open Sans', sans-serif;
  padding: 10px;
  width: 100%;
  background: #fff;
  text-align: center;
  position: relative;
  transition: all .5s ease 0s
}

.product-grid6:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, .3)
}

.product-grid6 .product-image6 a {
  display: block
}

.product-grid6 .product-image6 img {
  width: 100%;
  height: auto;
  transition: all .5s ease 0s
}

.product-grid6:hover .product-image6 img {
  transform: scale(1.1)
}

.product-grid6 .product-content {
  padding: 12px 12px 15px;
  transition: all .5s ease 0s
}

.product-grid6:hover .product-content {
  opacity: 0
}

.product-grid6 .title {
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
  margin: 0 0 10px;
  transition: all .3s ease 0s
}

.product-grid6 .title a {
  color: #000
}

.product-grid6 .title a:hover {
  color: #2e86de
}

.product-grid6 .price {
  font-size: 18px;
  font-weight: 600;
  color: #2e86de
}

.product-grid6 .price span {
  color: #999;
  font-size: 15px;
  font-weight: 400;
  text-decoration: line-through;
  margin-left: 7px;
  display: inline-block
}

.product-grid6 .social {
  background-color: #121c7d;
  width: 100%;
  padding: 20px 0px;
  margin: 0;
  list-style: none;
  opacity: 0;
  transform: translateX(-50%);
  position: absolute;
  bottom: -50%;
  left: 50%;
  z-index: 1;
  transition: all .5s ease 0s
}

.product-grid6:hover .social {
  opacity: 1;
  bottom: 4px;
}

.product-grid6 .social li {
  display: inline-block
}

.product-grid6 i.fa.fa-search {
  color: #909090;
}

.product-grid6 .social li a:hover i.fa.fa-search {
  color: #fff !important;
}

.product-grid6 .social li a {
  color: #909090;
  font-size: 16px;
  line-height: 45px;
  text-align: center;
  height: 45px;
  width: 45px;
  margin: 0 7px;
  border: 1px solid #909090;
  border-radius: 50px;
  display: block;
  position: relative;
  transition: all .3s ease-in-out
}

.product-grid6 .social li a:hover {
  color: #fff !important;
  background-color: #2196f3;
  width: 80px
}

.product-grid6 .social li a:after,
.product-grid6 .social li a:before {
  content: attr(data-tip);
  color: #fff;
  background-color: #2196f3;
  font-size: 12px;
  letter-spacing: 1px;
  line-height: 20px;
  padding: 1px 5px;
  border-radius: 5px;
  white-space: nowrap;
  opacity: 0;
  transform: translateX(-50%);
  position: absolute;
  left: 50%;
  top: -30px
}

.product-grid6 .social li a:after {
  content: '';
  height: 15px;
  width: 15px;
  border-radius: 0;
  transform: translateX(-50%) rotate(45deg);
  top: -20px;
  z-index: -1
}

.product-grid6 .social li a:hover:after,
.product-grid6 .social li a:hover:before {
  opacity: 1
}

@media only screen and (max-width:990px) {
  .product-grid6 {
    margin-bottom: 30px
  }
}

.stockLogo {
  margin-left: 370px;
  margin-top: 100px;
}

.stock-text {
  margin-left: 330px;
  margin-top: 100px;
}

.price-quick .icon-rupees:before {
  content: "\e924";
  color: #2196f3;
  font-size: 26px;
}

.price-quick {
  color: #2196f3;
  font-size: 35px;
}

.productRowImage {
  width: 50px;
  height: 50px;
  margin: 1px;
  cursor: pointer;
}

@media(max-width:768px) {

  .page-sidebar .page-sidebar-menu>li,
  .page-sidebar-closed.page-sidebar-fixed .page-sidebar-menu>li {
    transition: width 0.4s;
    margin: 0;
    padding: 0;
    border: 0;
    width: 100%;
  }

  .search-box {
    width: 225px;
  }
}

@media (max-width: 280px) {
  .search-box {
    width: 185px;
  }
}

.refreshIcon {
  z-Index: 2;
  cursor: 'pointer';
}

.product-gridBox {
  background: #e5e8eb;
  padding-top: 20px;
  min-height: 700px;
}

/*********************** input field materialze *****************/


.material-form-field {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  position: relative;
  display: block;
  color: #000000;
  padding: 25px 0 0px 0;
}

.material-form-field::after {
  content: "";
  display: block;
  color: #d50000;
  font-size: 12px;
  padding-top: 10px;
}

.material-form-field .material-form-field-label {
  position: absolute;
  display: block;
  top: 35px;
  left: 5px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  pointer-events: none;
  cursor: text;
}

.material-form-field input {

  display: block;
  width: 100%;
  border: none;
  font-size: 14px;
  color: #000000;
  border-bottom: 1px solid #000000;
  outline: none;
}

.material-form-field input:invalid {
  outline: none;
  box-shadow: none;
}

.material-form-field input:valid~.material-form-field-label,
.material-form-field input:focus~.material-form-field-label {
  top: 10px;
  font-size: 12px;
}

.material-form-field input:focus {
  outline: none;
  color: #3f51b5;
  padding-bottom: 8px;
  border-bottom-width: 3px;
  border-bottom-color: #3f51b5;
}

.material-form-field input:focus~.material-form-field-label {
  color: #3f51b5;
}

.material-form-field input:focus {
  outline: none;
  color: #3f51b5;
  padding-bottom: 8px;
  border-bottom-width: 3px;
  border-bottom-color: #3f51b5;
}

.material-form-field input:focus~.material-form-field-label {
  color: #3f51b5;
}

.material-form-field input:focus~.material-dropdown {
  /*
    If you want to animate this you will need to use some js to calculate it
    Optionally, you can simply display:block/none
    */
  height: 228px;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.2);
  transition: 0.2s ease-out height, step-start box-shadow 0.2s;
  -moz-transition: 0.2s ease-out height, step-start box-shadow 0.2s;
  -webkit-transition: 0.2s ease-out height, step-start box-shadow 0.2s;
}

.material-form-field.material-form-field-invalid {
  color: #d50000;
}

.material-form-field.material-form-field-invalid::after {
  content: attr(data-validationError);
}

.material-form-field.material-form-field-invalid input:focus~.material-form-field-label {
  color: #d50000;
}

.material-form-field .readonly {
  opacity: 1;
  background-color: #e9ecef !important;
  /* background: rgba(100, 181, 246, 0.22)!important; */
  mix-blend-mode: normal;
  border-radius: 0px !important;
  border: 0;
}

.material-form-field .form-control {
  border-radius: 0px;
}

/* .form-control:disabled, .form-control[readonly] {
    opacity: 1;
    background: rgba(100, 181, 246, 0.22)!important;
    mix-blend-mode: normal;
     border-radius: 0px;
} */

input[type=date].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control,
input[type=time].form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0px !important;
  border-bottom: 1px solid #dfd2d2 !important;

}

/* input[type="date"].form-control {
    padding: 0px 0px 0px 6px!important;
}

input[type="date"].form-control {
    padding: 0px 0px 0px 6px!important;
} */


.material-form-field input[type="date"]:focus {
  outline: none;
  color: #3f51b5;
  padding-bottom: 8px;
  border-bottom-width: 3px;
  border-bottom-color: #3f51b5 !important;
}

.imageDropdown {
  position: relative;
  display: inline-block;
  display: flex;
  align-items: center;
  height: 40px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;

}

/* Hide default HTML checkbox */
.switch input {
  display: none;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #1fd571 !important;
}

input.default:checked+.slider {
  background-color: #444;
}

input.primary:checked+.slider {
  background-color: #1fd571;
}

input.success:checked+.slider {
  background-color: #8bc34a;
}

input.info:checked+.slider {
  background-color: #3de0f5;
}

input.warning:checked+.slider {
  background-color: #FFC107;
}

input.danger:checked+.slider {
  background-color: #f44336;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.switcher {
  position: absolute;
  top: -11px;
  right: 12px;
}

.notes-comments {
  padding: 20px 20px;
  background: #a1a7ac12;
}





.quantity {
  display: inline-block;
}

.quantity .input-text.qty {
  width: 30px;
  height: 35px;
  padding: 0 5px;
  text-align: center;
  background-color: transparent;
  border: 1px solid #efefef;
}

.quantity.buttons_added {
  text-align: left;
  position: relative;
  white-space: nowrap;
  vertical-align: top;
}

.quantity.buttons_added input {
  display: inline-block;
  margin: 0;
  vertical-align: top;
  box-shadow: none;
}

.quantity.buttons_added .minus,
.quantity.buttons_added .plus {
  padding: 3px 6px 6px;
  height: 35px;
  background-color: #ffffff;
  border: 1px solid #efefef;
  cursor: pointer;
}

.quantity.buttons_added .minus {
  border-right: 0;
}

.quantity.buttons_added .plus {
  border-left: 0;
}

.quantity.buttons_added .minus:hover,
.quantity.buttons_added .plus:hover {
  background: #eeeeee;
}

.quantity input::-webkit-outer-spin-button,
.quantity input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  margin: 0;
}

.quantity.buttons_added .minus:focus,
.quantity.buttons_added .plus:focus {
  outline: none;
}

input.form-control {
  border-radius: 0px !important;
}



.editHiglight {
  background: #f8d568 !important;
}


.gstp {
  font-weight: normal;
}

.gradient-border {
  --border-width: 3px;
  position: relative;

  /* box-shadow: 0px 4px 14px -2px rgb(0 0 0 / 24%) inset; */
  text-transform: capitalize;
  padding: 4px;
  background: #fff;
  border-radius: var(--border-width);

}

.gradient-border::after {
  position: absolute;
  content: "";
  bottom: calc(-1 * var(--border-width));
  left: calc(-1 * var(--border-width));
  z-index: -1;
  width: calc(100% + var(--border-width) * 2);
  height: calc(100% + var(--border-width) * 2);
  /* background: linear-gradient(60deg, #5f86f2, #a65ff2, #f25fd0, #f25f61, #f2cb5f, #abf25f, #5ff281, #5ff2f0); */

  background: linear-gradient(60deg, #5f86f2, #2196f3, #4650a1, #7cb5e3, #2196f3, #54a9ed, #2196f3, #2196f3);
  background-size: 300% 300%;
  background-position: 0 50%;
  border-radius: calc(2 * var(--border-width));
  animation: moveGradient 2s alternate infinite;
  animation: fadeIn ease 4s;
  -webkit-animation: fadeIn ease 4s;
  -moz-animation: fadeIn ease 5s;
  -o-animation: fadeIn ease 5s;
  -ms-animation: fadeIn ease 5s;
  box-shadow: 0px 4px 14px -2px rgb(0 0 0 / 24%) inset;

}

@keyframes moveGradient {
  0% {
    background-position: 100% 50%;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.gradient-border-down {
  --border-width: 3px;
  position: relative;


  text-transform: uppercase;
  padding: 4px;
  background: #fff;
  border-radius: var(--border-width);

}

.gradient-border-down::after {
  position: absolute;
  content: "";
  bottom: calc(-1 * var(--border-width));
  left: calc(-1 * var(--border-width));
  z-index: -1;
  width: calc(100% + var(--border-width) * 2);
  height: calc(100% + var(--border-width) * 2);
  box-shadow: 0px 4px 14px -2px rgb(0 0 0 / 24%) inset;
  background: linear-gradient(60deg, #5f86f2, #2196f3, #4650a1, #7cb5e3, #2196f3, #54a9ed, #2196f3, #2196f3);
  background-size: 300% 300%;
  background-position: 0 50%;
  border-radius: calc(2 * var(--border-width));
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-name: fadeOut;
  -webkit-animation-name: fadeOut;
  -o-animation-name: fadeOut;
  -ms-animation-name: fadeOut;
  -moz-animation-name: fadeOut;
  color: 'red';
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}


.bg-light-clr {
  background-color: #f4f4f4 !important;
}

.fs-18 {
  font-size: 18px !important;
}

.check-order {
  vertical-align: middle;
}

.active-payement-row {
  background: #7cff8d;
}

.paid-payement-row {
  background: #E8EAF6;
}




.main-panel {
  // width: calc(100% - 112px);
  width: 100%;
  height: 100vh;
  // overflow: hidden;
  // margin-left:100px;
}

.bg-white {
  background: #fff !important;
}

.nav-pills .nav-link {
  background: 0 0;
  border: 0;
  border-radius: 0.25rem;
  font-size: 18px;
}

.p-main-box {
  max-width: 10rem;
  max-height: 10rem;


  .p-img {
    width: 4rem;
    height: 4rem;
    border-radius: 12px;
  }
}

.P-T-Name {
  width: 15rem;
}

.inner-p {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .card {
    width: 8rem;
    height: 8rem;
    margin-bottom: 20px;
    cursor: pointer !important;
  }

  .card-body {
    flex: 1 1 auto;
    padding: 0.5rem 0.5rem;
  }
}

.table th {
  white-space: nowrap;
}

.table td,
.table th {
  font-size: 14px !important;
  vertical-align: middle;
}

span.action-min {
  display: inline-flex;
  width: 45px;
  height: 45px;
  background: #f4f5f6;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  color: #42526a;
}

span.action-plus {
  display: inline-flex;
  width: 45px;
  height: 45px;
  background: #f4f5f6;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  color: #42526a;
}

.action-del {
  display: inline-flex;
  width: 45px;
  height: 45px;
  background: #ed5575;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  color: #ffffff;
}

.orderItem_name.border-0 {
  font-weight: bold;
  text-transform: capitalize;
  text-align: left;
}

thead.border-0 {
  color: #b8b4b4;
  /* text-transform: uppercase; */
  font-weight: 400 !important;
}




.cart-div {
  background: #fff;
  height: calc(99vh - (24px + 30px));

  .table th {
    white-space: nowrap;
    padding-bottom: 20px;
  }
}

.empty-cart {
  overflow: auto;
  height: calc(99vh - (40px + 170px));
}

.qtyInput {
  width: 25px;
  height: 25px;
  text-align: center;
}

.orderItem_qty {
  display: flex;
  justify-content: space-between;
  align-items: center;
}




.offerC {
  background-image: url('../assets/images/offer.jpg');
  background-size: cover;
  background-position: center;
  width: 100%;
}

.offerC2 {
  background-image: url('../assets/images/offer2.jpg');
  background-size: cover;
  background-position: center;
  width: 100%;
}




.Product-div {
  background: #fff;
  // height: calc(100vh - (0px + 0px));
  height: calc(99vh - (24px + 30px));

  .card-subtitle {
    margin-top: 0.25rem;
    margin-bottom: 0;
    font-size: 14px !important;
  }

}

.footer-fix {
  position: absolute;
  bottom: 5px;
  width: 100%;
}

.empty-product {
  overflow: auto;
  height: calc(99vh - (40px + 170px));
}


.bg-grey-gradient {
  background-image: linear-gradient(to right, #424d5e, #506070)
}


.offer-tag {
  background: #ffc119;
  color: #000;
  padding: 2px 5px;
  font-size: 11px;
  bottom: 8px;
  left: 5px;
  font-weight: bold;
  text-transform: uppercase;
}

.veg-tag {
  right: 5px;
  position: absolute;
  bottom: 7px;
  font-size: 8px;
  border: 1px solid #198754;
  display: flex;
  padding: 2px;
}

.nonveg-tag {
  right: 5px;
  position: absolute;
  bottom: 7px;
  font-size: 8px;
  border: 1px solid #ff1100;
  display: flex;
  padding: 2px;
}


/* width */

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  cursor: pointer !important;
}


/* Track */

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px #fff;
  border-right: 1px solid #506070;
}


/* Handle */

::-webkit-scrollbar-thumb {
  background: #506070;
  border-radius: 5px;
}


/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #506070;
}


/* Handle on hover */

::-moz-scrollbar-thumb:hover {
  background: rgb(167, 159, 159);
}


/* width */

::-moz-scrollbar {
  width: 3px;
}


/* Track */

::-moz-scrollbar-track {
  box-shadow: inset 0 0 5px #fff;
  // border-right: 2px solid #2196F3;
  border-right: 2px solid #506070;
}


/* Handle */

::-moz-scrollbar-thumb {
  background: #E93329;
  border-radius: 10px;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #506070 #ffff;
}




















// ------- login css -------- //


.text-clr-grey {
  color: #2c3542;
}

.master-login {
  height: 100%;
  position: absolute;
  width: 100%;
  background: linear-gradient(226deg, #506070 0%, #2c3542 100%);

  .logo-text {
    font-size: 30px;
    color: #f2484b;
    // line-height: 1;
    text-transform: capitalize !important;
    font-weight: 500;

    .food {
      // font-family: cursive;
      color: #9bb353;
    }
  }

  .master-content {
    background-color: rgba(255, 255, 255, 0.769);
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
    border-radius: 7px;
    width: 400px;
    margin: 0px auto;
    overflow: hidden;
    z-index: 12;
    position: relative;

    .error-message {
      text-transform: capitalize;
      line-height: 10px;
      font-size: 12px;
      font-weight: 300;
      background-image: linear-gradient(180deg, #F44336 0%, #B71C1C 100%);
    }

    .login-label {
      position: absolute;
      left: 25px;
      color: #8a8a8a;
    }

    .login-input {
      border: none;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      background-color: #E8EAF4 !important;
      padding-left: 0;
    }

    .login-input:focus {
      box-shadow: none;
    }

    .span-user {
      border: 0;
      background: #e8eaf4;
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }

    .view-password {
      position: absolute;
      right: 0;
      top: 16px;
    }

    .form-actions .btn {
      font-weight: 500;
      padding: 8px 18px;
      border-radius: 25px;
      background: #ffc119;
      // width: 100%;
      color: #2c3542;
      border-color: #ffc119;
      // background: linear-gradient(180deg, #b4d356 0%, #618001 100%);
      // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); 
    }

    .rememberme {
      display: inline-block;
      position: relative;
      cursor: pointer !important;
      font-size: 14px;
      -webkit-transition: all .3s;
      -moz-transition: all .3s;
      -ms-transition: all .3s;
      -o-transition: all .3s;
      transition: all .3s;
      padding-left: 18px;
      color: #8a8a8a;


      input {
        accent-color: #ffc119;
        position: absolute;
        top: 3px;
        left: 0;
      }
    }

    .forget-password {
      font-size: 14px;
      color: #8a8a8a;
      cursor: pointer !important;
    }
  }
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s !important;
}


.area {
  background: #4e54c8;
  background: -webkit-linear-gradient(to left, #8f94fb, #4e54c8);
  width: 100%;
  height: 100vh;


}

.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.2);
  animation: animate 30s linear infinite;
  bottom: -150px;

}

.circles li:nth-child(1) {
  left: 25%;
  width: 170px;
  height: 170px;
  animation-delay: 0s;
}

.circles li:nth-child(2) {
  left: 10%;
  width: 130px;
  height: 130px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 50px;
  height: 50px;
  animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 90px;
  height: 90px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 100px;
  height: 100px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 40px;
  height: 40px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 12s;
}

li.circle-spin {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

/* .circle-spin {
    display: flex;
    justify-content: center;
  } */

.circle-img {
  width: 90%;
  /* position: fixed; */
}

@keyframes animate {

  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 50%;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}

.loading-img {
  display: block !important;
  margin: 8% auto !important;
}

.toast-order {
  position: absolute;
  right: 45px;
  bottom: 100px;
  width: 318px !important;
  z-index: 11;
  text-transform: capitalize;
}

.toast-box-one {
  background: #212529a8 !important;
  border: none !important;
  border-bottom-right-radius: 0px !important;
  color: rgb(255, 255, 255);
}

.toast-box-one.arrow-bottom-right::before {
  content: " ";
  position: absolute;
  right: 4px;
  bottom: -17px;
  border-top: 17px solid #6d6f72;
  border-right: 0px solid transparent;
  border-left: 13px solid transparent;
  border-bottom: none;
}

.toast-info {
  position: absolute;
  right: 5px;
  bottom: 100px;
  width: 290px !important;
  z-index: 11;
  text-transform: capitalize;
}

.toast-box-two {
  background: #212529a8 !important;
  border-bottom-left-radius: 0px !important;
  border: none !important;
  color: rgb(255, 255, 255);
}

.toast-box-two.arrow-bottom-left::before {
  content: " ";
  position: absolute;
  left: 4px;
  bottom: -16px;
  border-top: 16px solid #6d6f72;
  border-right: 13px solid transparent;
  border-left: 0px solid transparent;
  border-bottom: none;
}


.toast-orderdetails {
  position: absolute;
  right: 200px;
  bottom: 90px;
  width: 318px !important;
  z-index: 11;
  text-transform: capitalize;
}

.toast-box-orderdetails {
  background: #212529a8 !important;
  border: none !important;
  border-bottom-right-radius: 0px !important;
  color: rgb(255, 255, 255);
}

.toast-box-orderdetails.arrow-bottom-right::before {
  content: " ";
  position: absolute;
  right: 4px;
  bottom: -17px;
  border-top: 17px solid #6d6f72;
  border-right: 0px solid transparent;
  border-left: 13px solid transparent;
  border-bottom: none;
}

.badge.bg-light-red {
  background: #ed5575 !important;
  border-color: #ed5575 !important;
}

.badge.bg-light-green {
  background: #46a846 !important;
  border-color: #46a846 !important;
}

.bg-light-red {
  background: #ed5575 !important;
  border-color: #ed5575 !important;
}

.bg-light-green {
  background: #46a846 !important;
  border-color: #46a846 !important;
}

.bg-light-blue {
  background: #506070 !important;
  border-color: #506070 !important;
  // background-image: linear-gradient(to right, #424d5e , #506070)
}

.bg-light-grey {
  background: #a8a8a8d4 !important;
  border-color: #b7b7b7 !important;
}

.summary-bg-red {
  background: linear-gradient(180deg, #ed5575 0%, #ed5575ab 100%) !important;
}

.summary-bg-green {
  background: linear-gradient(180deg, #46a846 0%, #46a846b8 100%) !important;
}

.summary-bg-yellow {
  background: linear-gradient(180deg, #b2500b 0%, #d39c56 100%) !important;
}

.summary-card {
  background: linear-gradient(180deg, #008CBA 0%, #6fadcb 100%);
  border: none !important;

  .summary-box {
    padding: 6px 2px;
    border-radius: 4px;
    min-height: 65px;
  }

  .label-text {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #ffc107;
    // position: absolute;
    // display: block;
  }

  .value-text {
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    position: absolute;
    z-index: 999;
    top: 22px;
  }
}

.view-password {
  position: absolute;
  right: 0;
  top: 16px;
}


.toast-manager {
  position: absolute;
  right: 20px;
  bottom: 20px;
  width: 310px !important;
  z-index: 11;
  text-transform: capitalize;
}

.toast-box-manager {
  background: #212529a8 !important;
  border: none !important;
  //   border-bottom-right-radius: 0px !important;
  color: rgb(255, 255, 255);
}

// .toast-box-manager.arrow-bottom-right::before {
//   content: " ";
//   position: absolute;
//   right: 4px;
//   bottom: -17px;
//   border-top: 17px solid #6d6f72;
//   border-right: 0px solid transparent;
//   border-left: 13px solid transparent;
//   border-bottom: none;
// }

.form-control.is-invalid {
  background-image: none !important;
}

.day-height {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}

.m-height {
  overflow: auto;
  height: 392px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  color: transparent;
  cursor: pointer !important;
  height: auto;
  width: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
}

.date-icon {
  position: absolute;
  right: 18px;
  bottom: 9px;
  cursor: pointer !important;
  color: #8a9396;
}

.text-light-blue {
  color: #8a9396 !important;
}

.text-light-blue:hover {
  color: #455A64 !important;
}

.text-light-red {
  color: #ed5575 !important;
}

.text-light-green {
  color: #46a846 !important;
}

.badge.bg-light-clr {
  background: #97d35e !important;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #506070 !important;
}


//MapWithPolyline component CSS

.newToast {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 11;
}


//map 
#select_div {
  padding: 10px;
  display: flex;
  justify-content: center;

}

.tab {
  flex-grow: 1;
  padding: 5px;
  list-style: none;
  border: solid 1px #CCC;
  text-align: center;
  cursor: pointer;
  color: #2196f3;
  font-size: 15px;
  font-weight: 500;
}

.panel {
  display: none;
}

.tab.is-active {
  background: #167bff;
  color: #FFF;
  transition: all 0.2s ease-out;
}

.panel.is-show {
  display: block;
}

.round {
  position: relative;
}

.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 20px;
  left: 0;
  position: absolute;
  top: 0;
  width: 20px;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 4px;
  opacity: 0;
  position: absolute;
  top: 5px;
  transform: rotate(-45deg);
  width: 10px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked+label {
  background-color: #66bb6a;
  border-color: #66bb6a;
}

.round input[type="checkbox"]:checked+label:after {
  opacity: 1;
}

.container .checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.container input:checked~.checkmark:after {
  display: block;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.modal.inner-pay {
  position: relative;
  background: transparent !Important;
}

.inner-pay .modal-dialog {
  transform: none;
  margin-top: 0px;
}

.s-card {
  border-radius: 0px !important
}



/*************** accor css *****************/
#main {
  margin: 10px 0;
  width: 100%;
}

#main #payment-cards .card {
  margin-bottom: 30px;
  border: 0;
}

#main #payment-cards .card .card-header {
  border: 0;
  -webkit-box-shadow: 0 0 20px 0 rgba(213, 213, 213, 0.5);
  box-shadow: 0 0 20px 0 rgba(213, 213, 213, 0.5);
  border-radius: 2px;
  padding: 0;
}

#main #payment-cards .card .card-header .btn-header-link {
  color: #fff;
  display: block;
  text-align: left;
  background: linear-gradient(180deg, #3F51B5 0%, #5C6BC0 100%);
  color: #222;

  box-shadow: none;
}

.bg-sch-r {
  background: #5a69bf;
}

#main #payment-cards .card .card-header .btn-header-link:after {
  content: "\f107";
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  float: right;
  position: absolute;
  right: 7px;
  top: 24%;
  font-size: 24px;
}

.balance-sch {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 0px;
}

#main #payment-cards .card .card-header .btn-header-link.collapsed {
  background: linear-gradient(180deg, #3F51B5 0%, #5C6BC0 100%);
  color: #fff;
}

#main #payment-cards .card .card-header .btn-header-link.collapsed:after {
  content: "\f106";
}

#main #payment-cards .card .collapsing {
  background: #fff;
  line-height: 30px;
}

#main #payment-cards .card .collapse {
  border: 0;
}

#main #payment-cards .card .collapse.show {
  background: #fff;
  line-height: 30px;
  color: #222;
}

.create-schedule-btn {
  font-size: 24px;
  color: #fa206c;
  cursor: pointer;
  margin-left: -20px;
}

.create-schedule-btn-disable {
  font-size: 24px;
  color: gray;
  cursor: pointer;
  margin-left: -20px;
}

// .accordion-button {
//     /* background: linear-gradient(
//         180deg
//         , #3F51B5 0%, #5C6BC0 100%);
//     color: #fff !important; */
// }
.tooltip.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: #AFB42B;
}

.tooltip.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: #AFB42B;
}

.tooltip.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: #AFB42B;
}

.tooltip.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: #AFB42B;
}

.edit-btn {
  background: linear-gradient(180deg, #3F51B5 0%, #5C6BC0 100%);
  border-radius: 4px;
  color: #fff;
  border: 1px solid #dfdfdf;
  /* box-shadow: 0 3px 6px rgba(0,0,0,.2), 0 3px 6px rgba(0,0,0,.26)!important; */
  font-size: 14px !important;
  height: 100%;
  width: 100%;
}

.page-sidebar-menu-closed .nav-item.open {
  width: 60px;
}

.accordion-button.collapsed::after,
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

@media (min-width: 992px) {
  .page-sidebar {
    z-index: 999;
  }
}

@media (max-width: 280px),
(max-width: 320px),
(max-width: 480px),
(max-width: 568px),
(max-width: 731px),
(max-width: 768px) {
  .page-sidebar-menu {
    margin-top: 0px !important;
    height: auto;
  }
}

.side-scrollbar {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(99vh - (40px + 80px));
  position: absolute !important;
  -ms-overflow-style: none;
  /* IE 11 */
  scrollbar-width: none;
  /* Firefox 64 */
}

.side-scrollbar::-webkit-scrollbar {
  display: none;
}

@media(max-width:768px) {
  .side-scrollbar {
    margin-top: 0px !important;
    height: auto;
    position: unset !important;
  }

}

.page-sidebar-closed .page-sidebar-menu-closed {
  display: inline-table !important;
}

.a-titles {
  white-space: nowrap;
}

.summary-table {
  min-width: 300px;
  overflow-x: auto;
}

.modal-close-button {
  cursor: pointer;
}


.form-control:disabled,
.form-control[readonly] {
  padding-left: .75rem !important;
  color: #9b9b9b !important;
}

.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-select~label {
  opacity: 1 !important;
}

.main-loader {
  display: inline-flex;
  justify-content: center;
  margin-top: 100px;
}

.check-move {
  position: absolute;
  left: 115px;
  top: 8px;
}

/* .dropDown-menu, .dropdown-menu-end, .show{
    position: absolute !important;
    inset: 0px 0px auto auto !important;
    margin: 0px !important;
    transform: translate(0px, 16px) !important;
} */




.profile-page .card {
  /* max-width: 340px; */
  margin: auto;
  max-height: 500px;
  overflow-y: auto;
  position: relative;
  z-index: 1;
  overflow-x: hidden;
  background-color: white;
  display: flex;
  transition: 0.3s;
  flex-direction: column;
  border-radius: 0px !important;
  /* border-radius: 10px;
  box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.2); */
}

.profile-page .card[data-state="#about"] {
  height: auto;
}

.profile-page .card[data-state="#about"] .card-main {
  padding-top: 0;
}

.profile-page .card[data-state="#contact"] {
  height: auto;
}

.profile-page .card[data-state="#experience"] {
  height: 550px;
}

.profile-page .card.is-active .card-header {
  height: 80px;
}

.profile-page .card.is-active .card-cover {
  height: 100px;
  top: -50px;
}

.profile-page .card.is-active .card-avatar {
  transform: none;
  left: 20px;
  width: 50px;
  height: 50px;
  bottom: 10px;
}

.profile-page .card.is-active .card-fullname,
.profile-page .card.is-active .card-jobtitle {
  left: 86px;
  transform: none;
}

.profile-page .card.is-active .card-fullname {
  bottom: 18px;
  font-size: 19px;
}

.profile-page .card.is-active .card-jobtitle {
  bottom: 16px;
  letter-spacing: 1px;
  font-size: 10px;
}

.profile-page .card-header {
  position: relative;
  display: flex;
  height: 200px;
  flex-shrink: 0;
  width: 100%;
  transition: 0.3s;
}

.profile-page .card-header * {
  transition: 0.3s;
}

.profile-page .card-cover {
  width: 100%;
  height: 100%;
  position: absolute;
  height: 160px;
  top: -20%;
  left: 0;
  will-change: top;
  background-size: cover;
  background-position: center;
  filter: blur(30px);
  transform: scale(1.2);
  transition: 0.5s;
}

.profile-page .card-avatar {
  width: 100px;
  height: 100px;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  -o-object-position: center;
  object-position: center;
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) translateY(-64px);
}

.profile-page .card-fullname {
  position: absolute;
  bottom: 0;
  font-size: 22px;
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
  transform: translateY(-10px) translateX(-50%);
  left: 50%;
}

.profile-page .card-jobtitle {
  position: absolute;
  bottom: 0;
  font-size: 11px;
  white-space: nowrap;
  font-weight: 500;
  opacity: 0.7;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  margin: 0;
  left: 50%;
  transform: translateX(-50%) translateY(-7px);
}

.profile-page .card-main {
  position: relative;
  flex: 1;
  display: flex;
  padding-top: 10px;
  flex-direction: column;
}

.profile-page .card-subtitle {
  font-weight: 700;
  font-size: 13px;
  margin-bottom: 8px;
}

.profile-page .card-content {
  padding: 20px;
  min-height: 200px;
}

.profile-page .card-desc {
  line-height: 1.6;
  color: #636b6f;
  font-size: 14px;
  margin: 0;
  font-weight: 400;
  font-family: "DM Sans", sans-serif;
}

.profile-page p {
  font-size: 12px;
  color: #444;

}


.profile-page .card-social {
  display: flex;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 30px;
}

.profile-page .card-social svg {
  fill: #a5b5ce;
  width: 16px;
  display: block;
  transition: 0.3s;
}

.profile-page .card-social a {
  color: #8797a1;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  background-color: rgba(93, 133, 193, 0.05);
  border-radius: 50%;
  margin-right: 10px;
}

.profile-page .card-social a:hover svg {
  fill: #637faa;
}

.profile-page .card-social a:last-child {
  margin-right: 0;
}

.profile-page .card-buttons {
  display: flex;
  background-color: #fff;
  margin-top: auto;
  position: sticky;
  bottom: 0;
  left: 0;
}

.card-buttons button {
  flex: 1 1 auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: 0;
  font-size: 13px;
  border: 0;
  padding: 15px 5px;
  cursor: pointer;
  color: #5c5c6d;
  transition: 0.3s;
  font-family: "Jost", sans-serif;
  font-weight: 500;
  outline: 0;
  border-bottom: 3px solid transparent;
}

.profile-page .card-buttons button.is-active,
.card-buttons button:hover {
  color: #2b2c48;
  border-bottom: 3px solid #8a84ff;
  background: linear-gradient(to bottom, rgba(127, 199, 231, 0) 0%, rgba(207, 204, 255, 0.2) 44%, rgba(211, 226, 255, 0.4) 100%);
}

.profile-page .card-section {
  display: none;
}

.profile-page .card-section.is-active {
  display: block;
  -webkit-animation: fadeIn 0.6s both;
  animation: fadeIn 0.6s both;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translatey(40px);
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translatey(40px);
  }

  100% {
    opacity: 1;
  }
}

.profile-page .card-timeline {
  margin-top: 30px;
  position: relative;
}

.profile-page .card-timeline:after {
  background: linear-gradient(to top, rgba(134, 214, 243, 0) 0%, #516acc 100%);
  content: "";
  left: 42px;
  width: 2px;
  top: 0;
  height: 100%;
  position: absolute;
  content: "";
}

.profile-page .card-item {
  position: relative;
  padding-left: 60px;
  padding-right: 20px;
  padding-bottom: 30px;
  z-index: 1;
}

.profile-page .card-item:last-child {
  padding-bottom: 5px;
}

.profile-page .card-item:after {
  content: attr(data-year);
  width: 10px;
  position: absolute;
  top: 0;
  left: 37px;
  width: 8px;
  height: 8px;
  line-height: 0.6;
  border: 2px solid #fff;
  font-size: 11px;
  text-indent: -35px;
  border-radius: 50%;
  color: rgba(134, 134, 134, 0.7);
  background: linear-gradient(to bottom, #a0aee3 0%, #516acc 100%);
}

.profile-page .card-item-title {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 5px;
}

.profile-page .card-item-desc {
  font-size: 13px;
  color: #6f6f7b;
  line-height: 1.5;
  font-family: "DM Sans", sans-serif;
}

.profile-page .card-contact-wrapper {
  margin-top: 20px;
}

.profile-page .card-contact {
  display: flex;
  align-items: center;
  font-size: 13px;
  color: #6f6f7b;
  font-family: "DM Sans", sans-serif;
  line-height: 1.6;
  cursor: pointer;
}

.profile-page .card-contact+.card-contact {
  margin-top: 16px;
}

.profile-page .card-contact svg {
  flex-shrink: 0;
  width: 30px;
  min-height: 34px;
  margin-right: 12px;
  transition: 0.3s;
  padding-right: 12px;
  border-right: 1px solid #dfe2ec;
}

.profile-page .contact-me {
  border: 0;
  outline: none;
  background: linear-gradient(to right, rgba(83, 200, 239, 0.8) 0%, rgba(81, 106, 204, 0.8) 96%);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
  color: #fff;
  padding: 12px 16px;
  width: 100%;
  border-radius: 5px;
  margin-top: 25px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  font-family: "Jost", sans-serif;
  transition: 0.3s;
}


.calendar {
  --side-padding: 20px;
  --border-radius: 34px;
  --accent-br: 15px;
  width: 100%;
}

.calendar select {
  background-color: #f3f4f6;
  padding: 15px 20px;
}

.calendar__opts,
.calendar__buttons {
  background-color: #fff;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 15px;
}

.calendar__opts {
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
  padding: 20px var(--side-padding);
}

.calendar__body {
  background-image: linear-gradient(to bottom, #f3f4f6, #fff);
}

.calendar__days {
  background-color: #fff;
  padding: 0 var(--side-padding) 10px;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.calendar__days>div {
  text-align: center;
  font-weight: 700;
  font-size: 1.02rem;
  color: #c5c8ca;
}

.calendar__dates {
  padding: 10px var(--side-padding);
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.calendar__date {
  --height: calc(400px / 6 - var(--side-padding));
  text-align: center;
  height: var(--height);
  line-height: var(--height);
  font-weight: 600;
  font-size: 1.02rem;
  cursor: pointer;
  position: relative;
}

.calendar__date::before {
  content: "";
  position: absolute;
  background-color: rgba(255, 255, 255, 0);
  width: 100%;
  height: calc(var(--height) * 0.9);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: var(--accent-br);
  transition: background-color 0.3s ease;
}

.calendar__date:not(.calendar__date--selected):not(.calendar__date--grey):hover::before {
  background-color: #ededed;
}

.calendar__date--grey {
  color: #c5c8ca;
  cursor: not-allowed;
}

.calendar__date--selected {
  color: #ff374b;
}

.calendar__date--selected::before {
  background-color: #ffeaec;
  border-radius: 0px;
}

.calendar__date--first-date::before {
  border-top-left-radius: var(--accent-br);
  border-bottom-left-radius: var(--accent-br);
}

.calendar__date--last-date::before {
  border-top-right-radius: var(--accent-br);
  border-bottom-right-radius: var(--accent-br);
}

.calendar__date--range-start::after {
  content: "";
  position: absolute;
  bottom: 3px;
  border-radius: 24px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ff374b;
  width: 10px;
  height: 4px;
}

.calendar__date--range-end {
  color: #fff;
}

.calendar__date--range-end::before {
  box-shadow: 0 15px 20px -3px rgba(255, 55, 75, 0.35);
  background-color: #ff374b;
  border-radius: var(--accent-br);
  z-index: 1;
}

.calendar__date--range-end::after {
  content: "";
  position: absolute;
  height: calc(var(--height) * 0.9);
  background-color: #ffeaec;
  width: 50px;
  top: 50%;
  right: 50%;
  transform: translateY(-50%);
}

.calendar__date span {
  position: relative;
  z-index: 1;
}

.calendar__buttons {
  padding: 10px var(--side-padding) 20px;
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}

.calendar__button {
  cursor: pointer;
}

.calendar__button--grey {
  background-color: #f3f4f6;
}

.calendar__button--primary {
  background-color: #1e2a8c;
  color: #fff;
  transition: box-shadow 0.3s cubic-bezier(0.21, 0.68, 0.09, 0.27), transform 0.2s linear;
}

.calendar__button--primary:hover {
  box-shadow: 0 20px 30px -13px rgba(23, 82, 255, 0.45);
  transform: translateY(-3px);
}

.calendar__button--primary:active {
  box-shadow: 0 10px 10px -6px rgba(23, 82, 255, 0.45);
  transform: translateY(-1px);
}



.calendar select,
.calendar button {
  font-family: inherit;
  font-weight: 700;
  font-size: 1.02rem;
  border-radius: 20px;
  outline: none;
  border: 0;
  padding: 15px 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.calendar select {
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='960px' height='560px' viewBox='0 0 960 560' enable-background='new 0 0 960 560' xml:space='preserve'%3E%3Cg id='Rounded_Rectangle_33_copy_4_1_'%3E%3Cpath d='M480,344.181L268.869,131.889c-15.756-15.859-41.3-15.859-57.054,0c-15.754,15.857-15.754,41.57,0,57.431l237.632,238.937 c8.395,8.451,19.562,12.254,30.553,11.698c10.993,0.556,22.159-3.247,30.555-11.698l237.631-238.937 c15.756-15.86,15.756-41.571,0-57.431s-41.299-15.859-57.051,0L480,344.181z'/%3E%3C/g%3E%3C/svg%3E");
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: calc(100% - var(--side-padding)) center;
}

.outgo-value {
  color: #ef2323;
}

.income-value {
  color: green;
}

.balance-box {
  font-size: 20px;
  text-transform: capitalize;
  color: #000;
  font-weight: bold;
}

.balance-box:hover {
  font-size: 20px;
  text-transform: capitalize;
  color: #5C6BC0;
  font-weight: bold;
}

.edit-profile {
  position: absolute;
  right: 12px;
  top: 12px;
}

.Advnaced-payment {
  position: absolute;
  top: 37px;
  left: 32px;
  color: #ff9800;
  text-decoration: none;
}

.transaction-table td {
  position: relative;
}

.amount-td {
  width: 200px;
}



.progress {
  width: 100%;
  max-width: 260px;
  height: 6px;
  background: #e1e4e8;
  border-radius: 3px;
  overflow: hidden;
}

.progress .progress-bar {
  display: block;
  height: 100%;
  background: linear-gradient(90deg, #ffd33d, #ea4aaa 17%, #b34bff 34%, #01feff 51%, #ffd33d 68%, #ea4aaa 85%, #b34bff);
  background-size: 300% 100%;
  -webkit-animation: progress-animation 2s linear infinite;
  animation: progress-animation 2s linear infinite;
}

@-webkit-keyframes progress-animation {
  0% {
    background-position: 100%;
  }

  100% {
    background-position: 0;
  }
}

@keyframes progress-animation {
  0% {
    background-position: 100%;
  }

  100% {
    background-position: 0;
  }
}


.card.theme {
  width: 440px;
  max-width: 100%;
  min-height: 191px;
  max-height: 191px;
}

.main .card {
  flex-direction: row;
  border-radius: 18px !important;
  min-height: 150px;
  margin-bottom: 25px !important;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 3px 0px 10px #00000029;
  opacity: 1;
  border: none !important;
}

.card-body.bg-red {
  display: inline-block;
  width: 276px;
}

.bg-red {
  background: #E93329 0% 0% no-repeat padding-box;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.bg-navy {
  background: #004c56;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.card-body-right {
  display: inline-block;
  width: 85px;
  color: #004c56;
  padding: 26px 10px;
}

.card-body-right h5 {
  font-size: 22px;
  letter-spacing: 0;
  color: #ffffff;
  opacity: 1;
  margin-bottom: 0px;
  font-weight: 700;
  font-family: "Open Sans";
  text-align: center;
  margin-top: 20px;
}

span.grid-action {
  position: absolute;
  top: 4px;
  right: 14px;
}

.card-status ul {
  list-style-type: none;
  margin-left: 0px;
  padding-left: 0px;
  line-height: 13px;
  font-size: 9px;
  letter-spacing: 0;
  color: #004C56;
  opacity: 1;
  min-width: 120px;
}

.card-body-right hr {
  border-color: #e9332966;
}

.missed-c {
  margin: 0px;
  text-align: center;
  color: #fff !important;
  font-size: 11px;
}

.card-description {
  min-height: 90px;
  line-height: 14px;
  padding-top: 8px;
}

.value-text {
  color: #fff;
  font-weight: bold;
}

span.watch {
  font-size: 9px;
  color: #fff;
  vertical-align: middle;
  padding: 0px 0px 5px 0px;
  display: inline-block;
  letter-spacing: 0;
  color: #FFFFFF;
  opacity: 1;
}

// span.user-image img {
//   width: 33px;
//   height: 33px;
//   border-radius: 50%;
//   display: inline-block;
// }
.user-name {
  color: #fff;
  font-size: 11px;
  display: inline-block;
  padding-left: 10px;
  font-family: "Open Sans";
}

.card-description {
  min-height: 90px;
  line-height: 14px;
  padding-top: 8px;
}

p.card-text {
  letter-spacing: 0;
  color: #FFFFFF;
  opacity: 1;
  font-size: 11px;
}

.card-label {
  font-size: 11px;
  color: #fff;
}

#overlay {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  cursor: pointer;
}

.un-image {
  background: #9c27b0;
  padding: 4px
}

.out-go-icon {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f53131;
  color: #fff;
  font-size: 20px;
}

.income-icon {
  background: #969320;
  padding: 4px
}

.bg-pending {

  background-image: linear-gradient(180deg, #FFB74D 0%, #FF9800 100%);
}

.bg-overdue {
  background-image: linear-gradient(180deg, #F44336 0%, #B71C1C 100%);
}

.progress-v {
  display: -ms-flexbox;
  display: flex;
  height: 0.8rem;
  overflow: hidden;
  font-size: .75rem;
  background-color: #e9ecef;
  box-shadow: 0 1px 3px rgb(0 0 0 / 10%), 0 1px 2px rgb(0 0 0 / 18%);
}

.step-yellow {
  width: 25%;
  background-color: orange;
}

.step-red {
  width: 35%;
  background-color: red;
}

.step-green {
  width: 20%;
  background-color: green;
}

.step-grey {
  width: 20%;
  background-color: grey;
}


// MapWithPolylines component start
#select_div {
  display: flex;
  justify-content: flex-start;
}

.main_div_elements {
  background-color: #0d6efd;
  border-radius: 20px;
  border: none;
  width: 12%;
  margin-right: 10px;
  color: #fff;
}

.main_div_elements:hover {
  background-color: #ffc107;
}

/* DataTableDemo.css */
.datatable-templating-demo .table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.datatable-templating-demo .user-image {
  width: 25px;
  height: 25px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.global-search-input {
  width: 20vw;
}

.p-dialog .p-dialog-header {
  background: #385A64 !important;
  color: #ffff;
}

.btn-clr {
  background: #FFC801 !important;
}

.p-dialog .p-dialog-header-icons {
  align-self: center !important;
}

.p-tabview .p-tabview-panels {
  padding: 0 !important;
}

.p-dialog .p-dialog-header .p-dialog-title {
  font-weight: 500 !important;
  font-size: 1.5rem !important;
  color: white;
  line-height: 1.2;
}

.p-confirm-dialog-reject,
.p-confirm-dialog-accept {
  font-size: 0.875rem !important;
  padding: 0.65625rem 1.09375rem !important;
}

.status-badge {
  min-width: 15px !important;
  height: 15px !important;
  /* line-height: 6.25rem; */
  top: 7px !important;
  right: 2px !important;
}

.icon-size {
  font-size: 40px !important;
  // margin-top: -3px;
}

.action-button {
  margin-top: 16%;
}

.field-kyc-image {
  display: block;
  position: relative;
}

.field-kyc-label {
  top: 0;
  font-size: 12px;
  margin-top: -20px;
  position: absolute;
  left: 0.75rem;
}

.image-card {
  background-image: url('../assets/images/galleria7.jpg');
  height: 500px;
  padding: 10px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

// .btn-outline{
//   background-color: transparent !important;
//     color: #6366F1 !important;
//     border: 1px solid !important;
// }
// .btn-outline:focus {
//   box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #b1b3f8, 0 1px 2px 0 black !important;
// }
// .btn-outline:enabled:active{
//   background: #4338CA !important;
//   color: #ffffff !important;
//   border-color: #4338CA !important;
// }
// .btn-outline:hover{
//   background: #4F46E5 !important;
//     color: #ffffff !important;
//     border-color: #4F46E5 !important;
// }
// .btn-outline:visited {
//   background: #4338CA !important;
//   color: #ffffff !important;
//   border-color: #4338CA !important;
// }
// .btn-outline:target{
//   background: #4338CA !important;
//   color: #ffffff !important;
//   border-color: #4338CA !important;
// }
// .btn-outline:focus-visible
// {
//   background: #4338CA !important;
//   color: #ffffff !important;
//   border-color: #4338CA !important;
// }
// .btn-outline:focus-within{
//   background: #4338CA !important;
//   color: #ffffff !important;
//   border-color: #4338CA !important;
// }
.focus-background {
  background-color: lightgoldenrodyellow !important;
}

// .p-float-label .label-number{
//   left: 15%;
// }
.main-dialog {
  height: 700px;
  overflow: auto
}

.field-image {
  display: block;
  position: relative;
}

.field-label {
  top: 0;
  font-size: 12px;
  margin-top: -20px;
  position: absolute;
  left: 0.75rem;
}

.p-dialog .p-dialog-content {
  padding: 1rem 1.5rem !important;
}

.action-field {
  display: block;
  position: relative;
}

.action-label {
  top: 0;
  font-size: 12px;
  margin-top: -20px;
  position: absolute;
  left: 0.75rem;
}

.selectBtn-sm {
  height: 44px;
}

.p-button.p-button-sm {
  font-size: 0.875rem !important;
  padding: 0.4rem 0.45rem !important;
}

.ui-button-icon-only {
  border-radius: 20px !important;
  max-width: 44px !important;
  min-width: 20px !important;
  font-size: 20px !important;
  padding: 5px 0px !important;
}